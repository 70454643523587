.footerplm{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .footerplm{
    display: flex;
    flex-direction: column;
  }
  .logos{
    margin-top: 20px;
  }
}